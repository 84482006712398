import { ImageOptions } from "./getContentfulImageUrl";
import { CartoonsResource } from "../../__generated__/cartoons-types";
import { CARTOONS_CLOUDINARY_IMAGE_BASE_URL } from "../constants";

export function getCartoonsImageUrl(
  item: CartoonsResource,
  { width }: ImageOptions,
) {
  const url = item.url?.replace(
    "https://img.liiift.io",
    CARTOONS_CLOUDINARY_IMAGE_BASE_URL,
  );
  return url?.replace(/(\/im\/)/, `/im:i:w_${width},f_auto/`);
}
