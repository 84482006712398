"use client";

import { useRouter } from "next/router";
import classNames from "classnames/bind";
import { Kicker } from "../../../kicker/kicker";
import { SupportDropdown } from "../support-dropdown/support-dropdown";
import {
  Asset,
  BlocksSupportCtaSupportModulesItem,
  ContactAssistant,
  ContactItem,
  DropdownRail as DropdownRailType,
  SearchField as SearchFieldType,
  SupportModule as SupportModuleType,
} from "../../../../__generated__/contentful-types";
import {
  isArtwork,
  isButton,
  isContactAssistant,
  isDropdownRail,
  isSearchField,
  isSupportModule,
} from "../../../../helpers/typeguards";
import { getPreviewImage } from "../../../../helpers/images/getImage";
import { getContentfulImageUrl } from "../../../../helpers/images/getContentfulImageUrl";
import { notNull } from "../../../../helpers/notNull";
import { ContentfulResponsiveImage } from "../../../contentful-responsive-image/contentful-responsive-image";
import { useEffect, useRef, Fragment, useState } from "react";
import { useViewportDimensions } from "../../../../helpers/useViewportDimensions";
import { useDevicePixelRatio } from "../../../../helpers/useDevicePixelRatio";
import {
  CosmosButton,
  CosmosIconFeedback,
  CosmosIconSearch,
  CosmosIconChevronRight,
} from "@cosmos/web/react";
import { useImageLoading } from "../../../../helpers/ImageLoadingContext";
import useEmblaCarousel from "embla-carousel-react";
import styles from "./support-module.module.css";
import { useLocaleContext } from "../../../../helpers/LocaleContext";

const DESKTOP_BREAKPOINT = 1000;
const cx = classNames.bind(styles);

export const SupportModule = ({
  module,
}: {
  module: BlocksSupportCtaSupportModulesItem;
}) => {
  const { viewportWidth } = useViewportDimensions();

  return (
    <div
      className={cx("container", {
        "container--white":
          viewportWidth < DESKTOP_BREAKPOINT &&
          isSupportModule(module) &&
          (isSearchField(module.interaction) ||
            isButton(module.interaction) ||
            isContactAssistant(module.interaction)),
        "container--single": isArtwork(module),
      })}
    >
      {isArtwork(module) ? (
        viewportWidth >= DESKTOP_BREAKPOINT &&
        module.artwork && <Artwork asset={module.artwork} />
      ) : (
        <Module module={module} viewportWidth={viewportWidth} />
      )}
    </div>
  );
};

function Artwork({ asset }: { asset: Asset }) {
  return (
    <div className={cx("artwork")}>
      <ContentfulResponsiveImage
        asset={asset}
        className={cx("artwork-image")}
      />
    </div>
  );
}

const Module = ({
  module,
  viewportWidth,
}: {
  module: SupportModuleType;
  viewportWidth: number;
}) => {
  if (isDropdownRail(module.interaction)) {
    return viewportWidth < DESKTOP_BREAKPOINT ? (
      <Fragment>
        <div className={cx("content", "content--white-background")}>
          <Kicker
            kind="underlined"
            text={module.name}
            className={cx("kicker")}
          />
          <h2 className={cx("title")}>{module.title}</h2>
          <p className={cx("description")}>{module.description}</p>
          {module.linkText && module.linkUrl && (
            <CosmosButton
              className={cx("link", "link--with-arrow")}
              kind="link"
              icon-placement="after"
              href={module.linkUrl}
            >
              <CosmosIconChevronRight
                slot="icon"
                className={cx("link-icon-arrow")}
                aria-hidden="true"
              />
              {module.linkText}
            </CosmosButton>
          )}
        </div>
        <DropdownRail
          props={module.interaction}
          kickerText={module.name}
          viewportWidth={viewportWidth}
        />
      </Fragment>
    ) : (
      <Fragment>
        <div className={cx("inner")}>
          <Kicker
            kind="underlined"
            text={module.name}
            className={cx("kicker")}
          />
          <h2 className={cx("title")}>{module.title}</h2>
          <p className={cx("description")}>{module.description}</p>
        </div>
        <DropdownRail
          props={module.interaction}
          kickerText={module.name}
          viewportWidth={viewportWidth}
        />
      </Fragment>
    );
  }
  if (isSearchField(module.interaction)) {
    return viewportWidth < DESKTOP_BREAKPOINT ? (
      <Fragment>
        <div className={cx("inner")}>
          <Kicker
            kind="underlined"
            text={module.name}
            className={cx("kicker")}
          />
          <h2 className={cx("title", "title--small")}>{module.title}</h2>
        </div>
        <SearchField props={module.interaction} />
      </Fragment>
    ) : (
      <Fragment>
        <div className={cx("inner")}>
          <Kicker
            kind="underlined"
            text={module.name}
            className={cx("kicker")}
          />
          <h2 className={cx("title")}>{module.title}</h2>
          <p className={cx("description")}>{module.description}</p>
        </div>
        <SearchField props={module.interaction} />
      </Fragment>
    );
  }
  if (isButton(module.interaction)) {
    return viewportWidth < DESKTOP_BREAKPOINT ? (
      <Fragment>
        <Kicker kind="underlined" text={module.name} className={cx("kicker")} />
        <h2 className={cx("title")}>{module.title}</h2>
        <p className={cx("description", "description--small")}>
          {module.description}
        </p>
        <div className={cx("button-container")}>
          <CosmosButton
            href={module.interaction.url ?? undefined}
            size="large"
            kind="primary"
            className={cx("button-button")}
          >
            {module.interaction.text}
          </CosmosButton>
          {module.linkText && module.linkUrl && (
            <CosmosButton
              href={module.linkUrl}
              size="large"
              kind="tertiary"
              appearance="dark"
              className={cx("button-button")}
            >
              {module.linkText}
            </CosmosButton>
          )}
        </div>
      </Fragment>
    ) : (
      <Fragment>
        <div className={cx("inner")}>
          <Kicker
            kind="underlined"
            text={module.name}
            className={cx("kicker")}
          />
          <h2 className={cx("title")}>{module.title}</h2>
          <p className={cx("description")}>{module.description}</p>
        </div>
        <div className={cx("button-container")}>
          <CosmosButton
            href={module.interaction.url ?? undefined}
            size="large"
            kind="primary"
            className={cx("button-button")}
          >
            {module.interaction.text}
          </CosmosButton>
          {module.linkText && module.linkUrl && (
            <CosmosButton
              href={module.linkUrl}
              size="large"
              kind="tertiary"
              appearance="dark"
              className={cx("button-button")}
            >
              {module.linkText}
            </CosmosButton>
          )}
        </div>
      </Fragment>
    );
  }
  if (isContactAssistant(module.interaction)) {
    return viewportWidth < DESKTOP_BREAKPOINT ? (
      <Fragment>
        <div className={cx("inner")}>
          <Kicker
            kind="underlined"
            text={module.name}
            className={cx("kicker")}
          />
          <h2 className={cx("title", "title--small")}>{module.title}</h2>
          <BotSection interaction={module.interaction} />
        </div>
      </Fragment>
    ) : (
      <Fragment>
        <div className={cx("inner")}>
          <Kicker
            kind="underlined"
            text={module.name}
            className={cx("kicker")}
          />
          <h2 className={cx("title")}>{module.title}</h2>
          <p className={cx("description")}>{module.description}</p>
          <BotSection interaction={module.interaction} />
        </div>
      </Fragment>
    );
  }
  return null;
};

function BotSection({ interaction }: { interaction: ContactAssistant }) {
  const imageLoading = useImageLoading();
  return (
    <div className={cx("bot-animation-container")}>
      {interaction.botImage?.url && (
        <picture>
          {(["avif", "webp"] as const).map((format, index) => (
            <source
              key={index}
              type={`image/${format}`}
              srcSet={[120, 240]
                .map((width) => {
                  const src = getContentfulImageUrl(
                    interaction.botImage?.url ?? "",
                    {
                      fm: format,
                      width,
                    },
                  );

                  return `${src} ${width}w`;
                })
                .join(", ")}
            />
          ))}

          <img
            loading={imageLoading}
            src={interaction.botImage.url}
            sizes="120px"
            className={cx("bot-animation")}
            alt={interaction.botImage.description ?? ""}
          />
        </picture>
      )}
      <CosmosButton
        href={interaction.buttonUrl ?? undefined}
        size="large"
        kind="primary"
        iconPlacement="before"
        className={cx("assistant-button")}
      >
        <CosmosIconFeedback
          slot="icon"
          className={cx("assistant-button-icon")}
        />
        {interaction.buttonText}
      </CosmosButton>
    </div>
  );
}

const DropdownRail = ({
  props,
  kickerText,
  viewportWidth,
}: {
  props: DropdownRailType;
  kickerText: string | null;
  viewportWidth: number;
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const [emblaViewportRef] = useEmblaCarousel({
    align: "center",
  });

  if (viewportWidth >= DESKTOP_BREAKPOINT) {
    return (
      <SupportDropdown
        props={props}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
      />
    );
  }
  return (
    <div className={cx("rail-items")}>
      <div className={cx("embla-viewport")} ref={emblaViewportRef}>
        <div className={cx("embla-container")}>
          {props.dropdownItemsCollection?.items
            .filter(notNull)
            .map((dropdownItem, index) => (
              <div className={cx("embla-slide")} key={index}>
                <DropdownRailItem
                  dropdownItem={dropdownItem}
                  kickerText={kickerText}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const DropdownRailItem = ({
  dropdownItem,
  kickerText,
}: {
  dropdownItem: ContactItem;
  kickerText: string | null;
}) => {
  const locale = useLocaleContext();

  const ref = useRef<HTMLAnchorElement | null>(null);

  const devicePixelRatio = useDevicePixelRatio();

  useEffect(() => {
    const element = ref.current;
    if (element) {
      const image = new Image();
      const width = Math.round(element.offsetWidth * devicePixelRatio);
      const height = Math.round(element.offsetHeight * devicePixelRatio);
      const url =
        dropdownItem.artwork?.url &&
        getContentfulImageUrl(dropdownItem.artwork.url, {
          width,
          height,
          fit: "fill",
        });

      if (url) {
        image.src = url;
        image.onload = () => {
          element.style.backgroundImage = `url(${image.src})`;
        };
      }
    }
  }, [devicePixelRatio, dropdownItem]);

  if (!dropdownItem.artwork) {
    return null;
  }

  return (
    <a
      className={cx("rail-item")}
      href={dropdownItem.linkUrl ?? undefined}
      style={{
        backgroundImage: `url(${getPreviewImage(
          dropdownItem.artwork,
          locale,
        )})`,
      }}
      ref={ref}
    >
      <span className={cx("rail-item-kicker")}>{kickerText}</span>
      <h3 className={cx("rail-item-title")}>{dropdownItem.title}</h3>
    </a>
  );
};

const SearchField = ({ props }: { props: SearchFieldType }) => {
  const locale = useLocaleContext();
  const { asPath: route } = useRouter();

  const isSugarFreePage = route.includes("red-bull-sugarfree");
  const isZeroCaloriesPage = route.includes("red-bull-zero-calories");
  const isEditionPage = route.includes("edition") || route.includes("editions");

  const hasCategory = isSugarFreePage || isZeroCaloriesPage || isEditionPage;

  const categoryRoute = getCategoryRoute(
    route,
    isSugarFreePage,
    isZeroCaloriesPage,
    isEditionPage,
    locale,
  );

  return (
    <form className={cx("search-field")} action={props.url ?? undefined}>
      <a
        className={cx("search-field-button")}
        href={props.url ? `${props.url}?category=${categoryRoute}` : undefined}
      >
        <CosmosIconSearch
          className={cx("search-field-icon")}
          aria-hidden="true"
        />
      </a>
      {hasCategory && (
        <input
          type="hidden"
          name="category"
          value={categoryRoute ?? undefined}
        />
      )}
      <input
        className={cx("search-field-input")}
        name="keywords"
        placeholder={props.text ?? undefined}
      />
    </form>
  );
};

function getCategoryRoute(
  route: string,
  isSugarFreePage: boolean,
  isZeroCaloriesPage: boolean,
  isEditionPage: boolean,
  locale: string,
): string | null {
  if (isSugarFreePage || isZeroCaloriesPage) {
    return route;
  }

  if (isEditionPage) {
    return `/${locale}/energydrink/red-bull-editions`;
  }

  return null;
}
