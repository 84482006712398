import { Asset } from "../../__generated__/contentful-types";
import { isAsset, isImage } from "../typeguards";
import { getContentfulImageUrl } from "./getContentfulImageUrl";
import { getRbImageUrl } from "./getRbImageUrl";
import { getCartoonsImageUrl } from "./getCartoonsImage";
import { DynamicImageItem } from "../../__generated__/crepo-types";
import { notNull } from "../notNull";
import { CartoonsResource } from "../../__generated__/cartoons-types";

export function getPreviewImage(
  item: Asset | CartoonsResource | DynamicImageItem,
  locale: string,
) {
  if ("__typename" in item) {
    if (item.__typename === "Resource") {
      return getCartoonsImageUrl(item, { width: 150 });
    }
    if (isAsset(item)) {
      return item.url && getContentfulImageUrl(item.url, { width: 150 });
    }
  }

  const firstFeaturedMedia = item.featuredMedia?.filter(notNull).find(isImage);

  const url =
    firstFeaturedMedia?.imageEssence?.__typename === "CloudinaryImage"
      ? firstFeaturedMedia.imageEssence.imageURL
      : firstFeaturedMedia?.imageSrc;

  return url && getRbImageUrl(url, { width: 150 }, locale);
}
